@import 'userVariables';
@import 'transitions';
@import 'inputItems';
@import 'appIcons';
@import 'templates';
@import 'composer';
@import 'fileUpload';


:root {
    font-family: "Roboto",sans-serif;
    font-size: .9em;
    font-weight: 400;
    line-height: 1;
    color: #666;
    text-align: left;

    margin: 0;
    padding: 0;
    overflow: hidden;
    user-select: none;
}

body {
    margin: auto;
}

// ALGEMEEN
.RELATIVE {
    position: relative;
}

.ABSOLUTE {
    position: absolute;
}

.NOSCROLL {
    overflow: hidden;
}
.FULL {
    width: 100%;
}
.FULL-H {
    height: 100%;
}
.FLEX {
    display: flex;
}
.FLEX-ROW {
    display: flex;
    gap: 10px;
}
.FLEX-COLUMN {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // height: 100%;
}
.FLEXWRAP {
    flex-wrap: wrap;
  }
  .FLEXSTRETCH {
    align-items: stretch;
  }
  .BETWEENFLEX {
    justify-content: space-between;
    align-items: top;
  }
  .CENTERFLEX {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .CENTERFLEX-V {
    display: flex;
    align-items: center;
  }
  .CENTERFLEX-H {
    display: flex;
    justify-content: center;
  }
  .ALIGNFLEX-T {
    align-items: flex-start;
  }
  .ALIGNFLEX-B {
    align-items: flex-end;
  }
  .LEFTFLEX {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .RIGHTFLEX {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
.GROW-MAX {
    flex-grow: 50;
}
.NOGAP {
    gap: 0px !important;
}
.SMALLGAP {
    gap: 5px !important;
}
.STICKY-T {
    position: sticky !important;
    top: 0;
}
.STICKY-T2 {
    position: sticky !important;
    top: 32px;
  }
  .STICKY-T::after, .STICKY-T2::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 100%;
    left: 0;
    z-index: -1;
    box-shadow: 0px 2px 4px 0px $dropShadowColor;
    border-top: 1px solid $gray-700;
}
  
.STICKY-L {
    position: sticky !important;
    left: 0;
}
.STICKY-L::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 100%;
    z-index: 1;
    box-shadow: 2px 0px 6px 0px $dropShadowColor;
    border-left: solid 1px rgb(0,0,0);
}
.MINCELL {
    width: 1px;
    white-space: nowrap;
}
.CENTER {
    text-align: center;
}
.NOBG {
    background: none !important;
    background-color: transparent !important;
}
.ELLIPSIS {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.UNSET-H {
    height: unset;
}
.UNSET-W {
    width: unset;
}
.HAND {
    cursor: pointer;
}
.SEPARATOR {
    border-bottom: $checkBorder;
    margin: $itemPaddingLarge 0;
}
h1, h2, h3 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
}
p {
    margin: 10px 0;
}
a {
    &.noDec {
        text-decoration: none;
        color: unset;
    }
}
img {
    &.full {
        width: 100%;
        height: auto;
    }
}
.UI-ERROR {
    color: $errorColor;
    font-weight: 600;
    font-style: italic;
}

// LAYOUT

.background {
    background-color: white;
    width: 100%;
    height: 100%;
    position: relative;
}

.rootContainer {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.softContainer {
    width: 100%;
    height: 100%;
    margin: auto;
    // padding-top: $softTopPadding;
    display: flex;
}

.pageWrapper {
    background-color: white;
    width: 100%;
    padding: $pagePadding;
    min-height: $pageMinHeight;
    border-radius: $softBorderRadius;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    margin: 0 30px 30px 0;

    // box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
    @include dropshadow;
}

.pageButton {
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

// NAVIGATION
.uiSidebar {
    min-width: 250px;
    max-width: 350px;
    padding: 20px;

    #properties {
        overflow-y: auto;
        padding-right: 15px;
    }
}
.uiItemProps {
    min-width: 250px;
    // min-height: 500px;
    padding: 0 20px; 
    margin-left: 20px;
    border-left: 5px solid $gray-300; 

    transition: transform 300ms ease-in-out;

    & .toggle:after {
        content: 'toggle';
        font-family: 'UIIcons';
        color: $gray-300;
        font-size: 2em;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-53%, -50%);
        background-color: white;
        cursor: pointer;

        transition: color 300ms ease-in,transform 300ms ease-in-out;
        
    }

    &.closed {
        transform: translateX(calc(100% - 15px));
        min-width: unset;

        & .toggle:after {
            color: $textColor;
            transform: translate(-53%, -50%) rotate(180deg);
        }
    }

}

// DASHBOARD
.uiModalContainer {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: $modalBackground;
    

    & > div {
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        padding: 40px;
        border-radius: 20px;
        background-color: white;
        max-width: 90%;

        column-gap: 20px;

        @include dropshadow;
    }

    &.uiModalAbsolute {
        position: fixed !important;
        left: 0;
        top: 0;
        z-index: 99999;
    }

    #modalContent {
        max-height: calc(90vh - 150px);
        padding-right: 15px;
        overflow-y: auto;
    }
}

.uiToggleContainerParent {
    &:not(:first-of-type) {
        padding-top: 0px;
    }

    #title {
        min-height: 26px;
    }
    #content > div {
        min-height: 200px;
    }

}
.uiToggleContainer {
    border-bottom: 1px solid $gray-400;
    padding-bottom: 10px;

    & > i {
       float: right; 
    }

    &:hover {
        color: $accentColor;
    }
}
.uiDropContainer {
    border: 1px dashed $gray-400;
    border-radius: $softBorderRadius;
    padding: $itemPadding;
    height: 100%;
    box-sizing: border-box;
    // max-height: 40%;
    overflow-y: auto;
}

.infoText {
    color: $gray-500;
    font-style: italic;
}

.button {
    background-color: $btnColor;
    color: white;
    width: 100%;
    height: 52px;
    text-align: center;
    line-height: 52px;

    &:disabled {
        background-color: $btnColorDisabled;
    }

    &.second {
        background-color: $gray-400;
    }

    &:active, &:focus {
        outline: none;
        border: none;

        @include dropshadow;
    }
}
.backBtn {
    background: $gray-200;   //$darkblue-10
    color: white;
    min-width: 44px;
    height: 44px;
    border-radius: $uiItemRadiusBig;
    position: absolute;
    left: 22px;
    top: 22px;

    &:hover, &:active {
        background: $gray-700;
    }
}

.tooltip {
    // min-width: 100px;
    // min-height: 150px;
    position: absolute;
    background-color: white;
    // z-index: 100;
    border-radius: 10px;
    // margin-left: 40px;
    // transform-origin: 0;
    // transform: translateY(-50%);
    z-index: 9999999999999;
    left: 10px;
    top: 10px;

    @include dropshadow;
    transition: all 0.18s ease-out 0.18s;

    // &::before {
    //     content: '';
    //     height: 79px;
    //     width: 27px;
    //     background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2025%2074.67%22%3E%0A%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23ffffff%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%0A%3Cg%20id%3D%22Layer_1-2%22%20data-name%3D%22Layer%201%22%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%2225%2074.67%2025%200%200%2037.17%2025%2074.67%22%2F%3E%3C%2Fg%3E%0A%3C%2Fsvg%3E");
    //     background-color: transparent;
    //     background-size: 100% auto;
    //     left: -27px;
    //     top: 50%;
    //     position: absolute;
    //     transform: translateY(-50%);
    //     pointer-events: none;
        
    // }
}

.tooltipContent {
    // padding: 20px;
    margin: 10px;
    
    & > .gallery {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        & > div img {
            max-height: 200px;
            width: auto;
        }
    }
}


// ICONS
.iconSmall {
    color: $iconColor;
    display: block;

    & img {
        height: 20px;
        width: auto;
        vertical-align: middle;
    }
}

// META PICKER
#metaPicker {
    max-height: calc(90vh - 150px);

    .sidebar {
        width: 250px;
        // overflow: auto;
    }

    .dropbar,
    .formbar {
        max-width: 75%;
        min-width: 400px;
        min-height: 300px;
        overflow: auto;
    }
}

.tabContainer {
    border-bottom: $checkBorder;

    .tab {
        
        border-left: $checkBorder;
        border-top: $checkBorder;
        border-right: $checkBorder;

        border-radius: $formItemBorderRadius $formItemBorderRadius 0 0;
        padding: $itemPadding;

        &:hover {
            color: $btnColor;
            background-color: $btnBGColor;
        }
        &.active {
            background-color: $btnBGColorActive;
            color: $btnColorActive;
            
            @include dropshadowSmall;
        }
    }
}

// @media (max-width:961px)  { 
.MD { 
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
    // .background {
    // }

    .pageWrapper {
        // max-width: calc(100% - 60px);
        padding: $pagePadding-m $pagePadding-m $pagePadding-m $pagePadding-m;
    }
}

// @media (max-width:800px)  { 
//     .pageWrapper {
//         margin: 30px;
//     }
// }

// @media (max-width:961px)  { 
// }

// @media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
// @media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
// @media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
// @media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
// @media (min-width:1281px) { /* hi-res laptops and desktops */ }