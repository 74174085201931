#imagePicker {
	* {
		font-family: unset;
	}

	.image-container {
		opacity: 0;
	
		&.show {
			opacity: 1;
		}
	}
	
	.dz-ui-header {
		padding: 10px;
		box-sizing: border-box;
	}
	
	.file-item-name {
		font-size: .75em !important;
	}

	.view-in-image-file-item {
		margin: 0 4% 4% 0;
	}
}