@import 'userVariables';

@font-face {
  font-family: 'UIIcons';
  src:
    url('../fonts/granaat.woff2?l9uado') format('woff2'),
    url('../fonts/granaat.ttf?l9uado') format('truetype'),
    url('../fonts/granaat.woff?l9uado') format('woff'),
    url('../fonts/granaat.svg?l9uado#granaat') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="gi-"], [class*=" gi-"], .gi-liga, .gi-icon::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'UIIcons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  content: attr(gi-icon);

}
.gi-icon {
    &.prefix {
      padding-right: $itemPadding;
    }
  
    &.btn {
      color: $gray-500;

      &:hover {
        color: $accentColor;
      }
    }

    &.gi-medium {
      font-size: 16pt;
    }
    &.gi-big {
        font-size: 20pt;
        padding-right: 10px;
    }
    &.icon {
      color: $accentColor;
    }
  
    &.active {
      color: $accentColor;
    }
  
    &.inactive {
      color: $gray-500;
    }
}
