@import 'userVariables';

.dragContainer {
    padding: $itemPadding;

    & .title {
        font-size: 1.4em;
        color: $textColor; 
    }

    & .filter {
        margin-bottom: $itemPadding;
    }

    & .dropZone {
        max-height: 400px;
        overflow-y: auto;
    }
}

.composeWrapper {
    width: calc(100% - 250px);
}
.node {
    // margin-top: $itemPaddingLarge;

    & .title {
        background-color: $gray-300;
        border-radius: $uiItemRadius;
        padding:  $itemPadding;
        color: $accentColor;
        cursor: pointer;
        overflow-x: hidden;

        span {
            max-width: calc(100% - 295px);
            display: inline-block;
        }

        &:after {
            content: attr(data-id);
            position: absolute;
            right: 40px;
            font-size: .7em;
            color: $gray-600;
            top: 50%;
            transform: translateY(-50%);
        }
        
        &:hover {
            background-color: $gray-400;
        }
    }

    & .subtitle {
        color: $accentColor-20;
        font-style: italic;
    } 
    
    
}

.dropZone {
    border: 1px dashed $gray-400;
    min-height: 150px;
    border-radius: $uiItemRadiusBig;

    &.smallZone {
        min-height: 40px;
    }

    &.active {
        background-color: $accentColor;
    }

    &.canDrop {
        background-color: $gray-500;
    }

    & .dropItem,
    & .clickItem {
        // min-height: 14px;
        padding: $itemPadding;
        border-radius: 6px;
        background-color: $gray-300;
        cursor: pointer;
        box-sizing: border-box;
        min-height: 26px;

        &.dropItem:hover {
            background-color: $gray-400;
            // 
        }
    }
}
