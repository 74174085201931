@import 'userVariables';

// GENERARL
@mixin formitemBase {
  padding: 10px;
  border-radius: $formItemBorderRadius;
  background-color: $formItemBg;
  color: $formItemColor;

  transition: background-color 0.5s ease-in, color 0.4s ease-in;

  &:hover {
    background-color: $formItemBgActive;
    color: $formItemColorActive;
  }

  & ul {
    list-style-type: none;
    margin: auto;
    padding: 6px;

    & li:not(.filter) {
      line-height: $inputSize;
      border-radius: $formItemBorderRadius;
      padding: $itemPadding;
      margin: $formItemMargin 0;
      transition: background-color 0.3s ease-in;
      white-space: nowrap;

      &.disabled {
        color: $gray-400;
        cursor: not-allowed;
      }

      &:hover:not(.disabled) {
        background-color: $gray-300;
      }
    }
  }
}

textarea {
  min-height: 50px;
}

input, textarea {
  body {
    font-family: "Roboto",sans-serif;
    font-weight: 400;
    line-height: 1;
}
    width: 100%;
    height: $inputSize;
    border-radius: 4px;
    border: $checkBorder;
    box-sizing: border-box;
    padding: 0 10px;

    &[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &:focus {
      outline: none;
      border: $checkBorderActive;
    }
}


.formitemLabel {
  margin-left: $itemPaddingLarge;
  // z-index: 0;


  &:focus-within {
    z-index: 999 !important;
  }

  &:before {
    content: attr(data-label);
    display: block;
    font-size: .8em;
    margin-bottom: $itemPadding;
    margin-left: -$itemPaddingLarge;
    opacity: .8;
  }
}


// ===================================================
// VALUE AS TEXT
.asText {
  font-size: .8em;
}

// ===================================================
// BUTTON
button {
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border: none;
    outline: none;
    border-radius: $uiItemRadius;
    font-size: 1em;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer; cursor: hand;
    padding: 0.5em;
    min-width: $btnSize;
    min-height: $btnSize;
    height: max-content;
    color: $btnColor;
    background-color: $btnBGColor;
    transition: background-color 0.5s ease-in, color 0.4s ease-in;

    &:hover {
      color: $btnColorActive;
      background-color: $btnBGColorActive;
    }

    &.second {
      background-color: $gray-300;

      &:hover {
        color: inherit;
        background-color: $gray-400;
      }
    }

    &:disabled {
      background-color: $btnBGColorDisabled;
      color: $btnColorDisabled;
      cursor: not-allowed;
    }    
}

// ===================================================
// SELECT
.dropdown {
  @include  formitemBase;

  // z-index: 10;
  padding-right: 35px;
  // & .values {}

  & .options {
    background-color: $gray-200;
    padding: $itemPadding;
    border-radius: 0 0 $formItemBorderRadius $formItemBorderRadius;
    z-index: 999;
    
    @include dropshadowSmall;
  }

  &:after {
    font-family: 'UIIcons';
    content: 'arrow-down';
    position: absolute;
    right: $itemPadding;
    top: 50%;
    transform: translateY(-50%);
  }
  // &.open:after {
  //   transform: rotate(180);
  // }  
}

// ===================================================
// CHECKBOX / RADIO

input[type="checkbox"],
input[type="radio"] {
  display:none;
}
.listHor {
  & .checklabel {
    flex-direction: column;
    width: 100%;
  }
  & .checkmark, .radiomark {
    position: relative;
    margin: 10px 0;
  }  
  & .checktext, .radiotext {
    padding: unset;
  }
}
.checklabel {
    // display: inline-block;
    display: inline-flex;
    align-items: center;
    position: relative;
    
    /* margin-bottom: 12px; */
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checktext, .radiotext {
    padding-left: 40px;
    display: inline-flex;
    gap: 5px;
    justify-content: center;
    align-items: center;

    &  > div {
      display: contents;
    }
  }
  .checkmark, .radiomark {
    position: absolute;
    top: 50%;
    left: 0;
    margin-right: 13px;
    border: $checkBorder;
    transform: translateY(-50%);
  }
  .checkmark {
    height: $checkmarkSize;
    width: $checkmarkSize;
    border-radius: 4px;
  }
  .radiomark {
    height: $checkmarkSize;
    width: $checkmarkSize;
    border-radius: 50%;
  }
//   .checklabel:hover input ~ .checkmark,
//   .checklabel:hover input ~ .radiomark {
//     /* border: solid 1px rgb(var(--mainTxt)); */
//     border: solid 1px rgb(var(--mainAccent));
//     /* background-color: rgb(var(--secBg)); */
//   }
  /* When the checkbox is checked, add a blue background */
//   .checklabel input:checked ~ .checkmark,
//   .checklabel input:checked ~ .radiomark {
//     /* border: solid 1px rgb(var(--mainTxtInactive)); */
//   }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after, 
  .checkmark:before, 
  .radiomark:after {
    content: "";
    position: absolute;
    display: none;
  }
  /* Show the checkmark when checked */
  .checklabel input:checked ~ .checkmark:after,
  .checklabel input:checked ~ .checkmark:before,
  .checklabel input:checked ~ .radiomark:after {
    display: block;
  }
  /* Style the checkmark/indicator */
  .checklabel .checkmark:after {
    left: calc(50% + 2px);
    top: calc(50% - 4px);
    width: 7px;
    height: 15px;
    border: solid $checkActiveColor;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg) translate(-100%, -30%);
  }
  .checklabel .checkmark:before {
    background-color: $formItemBgActive;
    right: -$checkmarkBorder;
    top: -$checkmarkBorder;
    width: 7px;
    height: 10px;
  }
  .checklabel .radiomark:after {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: $checkmarkCheckedSize;
    height: $checkmarkCheckedSize;
    background-color: $checkActiveColor;
    border-radius: 50%;
  }

/* SCROLLBAR */

::-webkit-scrollbar
{
  /* background: rgba(var(--mainBg), 0); 
  width: 24px;
  height: 24px;*/
  width: 14px;
  height: 14px;
  z-index: 6;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
	/* border: 10px solid rgba(var(--mainBg),0.1); */
	background-clip: padding-box;
}

::-webkit-scrollbar-thumb {
  /* background: rgba(var(--scrollbarThumb)); */
  border: solid 4px transparent;
  box-shadow: inset 0 0 14px 14px $accentColor-20;
  border-radius: 14px;
}
::-webkit-scrollbar-thumb:hover {
  /* background: rgb(var(--mainAccent)); */
  box-shadow: inset 0 0 14px 14px $accentColor;
}

::-webkit-scrollbar-track {
  /* background: rgb(var(--scrollbarTrack)); */
  margin: 10px;
  border: solid 4px transparent;
  box-shadow: inset 0 0 14px 14px $gray-300;
  border-radius: 14px;
}
::-webkit-scrollbar-track:hover
 {
  /* background: rgb(var(--scrollbarTrackHover)); */
  box-shadow: inset 0 0 14px 14px $gray-300;
}
::-webkit-scrollbar-button {
  display: none;
}

// ===================================================
  // SWITCH

  .switchbase {
    position: relative;
    display: block;
    width: 50px;
    height: 20px;
    border-radius: 10px;
    cursor: pointer;
    box-sizing:content-box;
    margin-top: 5px;
    outline: none;
    color: $textColor;
  }
  .switch {
    border: 1px solid $gray-100;
    background-color: $gray-100;
    box-shadow: inset 2px 2px 3px $dropShadowColor;

    &.FULL {
      width: 100%;
    }
  }
  .switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-sizing:content-box;
  }
  .switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: inherit;
    /* box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15); */
    box-sizing:content-box;
    
    &:before,
    &:after {
      position: absolute;
      top: 50%;
      width: 100%;
      line-height: 1;
      -webkit-transition: inherit;
      -moz-transition: inherit;
      -o-transition: inherit;
      transition: inherit;
      box-sizing:content-box;
      transform: translateY(-50%);
    }

    &:before {
      content: attr(data-off);
      right: 5px;
      color: $textColor;
      text-align: right;
    }

    &:after {
      content: attr(data-on);
      left: 5px;
      color: $textColor;
      opacity: 0;
      text-align: left;
    }

  }
  .switch-input:checked ~ .switch-label {
    // background: $keylime;
    
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }

  }
  .switch-handle {
    position: absolute;
    top: 2px;
    left: 4px;
    width: 16px;
    height: 16px;
    background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
    border-radius: 10px;
    box-shadow: 1px 1px 5px $dropShadowColor;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
      width: 12px;
      height: 12px;
      border-radius: 10px;
      box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
    }

  }

  .switch-input:checked ~ .switch-handle {
    left: 30px;
    box-shadow: -1px 1px 5px $dropShadowColor;
  }
  .switch-input.FULL:checked ~ .switch-handle {
    left: calc(100% - 20px);
  }


  // COLOR PICKER
  input[type="color"] {
    border-radius: 100%;
    height: $inputSize;
    width: $inputSize;
    border: $checkBorder;
    outline: none;
    padding: 0;
    -webkit-appearance: none;

    &::-webkit-color-swatch-wrapper {
      padding: 0; 
    }

    &::-webkit-color-swatch {
        border: none;
        border-radius: 100%;
    }
  }

  // FILE UPLOAD
  input[type="file"] {
    min-height: 250px;
    border-style: dotted;
    padding: 0.5em;
  }

// ===================================================
// CUSTOM

// TICKER
.ticker {
  & button {
    margin-top: 15px;
    min-width: $btnSizeSmall; 
    min-height: $btnSizeSmall; 
    font-size: 30pt;
    line-height: $btnSizeSmall;
  }

  & .smallBtn {
    margin-top: 0px !important;
    min-width: $inputSize !important;
    min-height:$inputSize !important;
    height: unset;
    font-size: 20pt;
    line-height: $inputSize !important;
  }
}