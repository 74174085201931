@import 'userVariables';

.pageContainer {
	overflow-y: auto;
	padding: 15px;
}

.pageContent {
    width: 100%;
    height: 100%;

	.pageGrid {
		display: grid;
		width: 100%;
		height: 100%;
		gap: 10px;

		& > div {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}
}

.productCard {
	background-color: rgba(0, 0, 0, .05);
	border-radius: $uiItemRadius;
	padding: $itemPadding;
    height: 100%;
	
	#thumb {
		width: 100px;
		height: auto;
		border-radius: 2px;
	}

	#title {
		font-weight: bold;
		margin-bottom: $itemPadding;
	}
}

.canDrop {
	background-color: $accentColor-20 !important;
}