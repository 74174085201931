// BASIS PAGES
// ===========================================================================

// RTL
.RTL-enter, .RTL-appear, .RTL-appear-done, .RTL-enter-done {
    transform: translateX(0);
}
.RTL-enter-active, .RTL-appear-active {
    animation-delay: 400ms;
    animation: rtl-in 300ms ease-in;
}
.RTL-leave, .RTL-exit, .RTL-leave-done, .RTL-exit-done {
    // transform: translateX(calc(-100% - 100px));
    opacity: 1;
}
.RTL-leave-active, .RTL-exit-active {
    animation: rtl-out 300ms ease-in;
}

// LTR
.LTR-enter, .LTR-appear, .LTR-appear-done, .LTR-enter-done {
    transform: translateX(0);
}
.LTR-enter-active, .LTR-appear-active {
    animation-delay: 300ms;
    animation: ltr-in 300ms ease-in;
}
.LTR-leave, .LTR-exit, .LTR-leave-done, .LTR-exit-done {
    // transform: translateX(calc(100% + 100px));
    opacity: 1;
}
.LTR-leave-active, .LTR-exit-active {
    animation: ltr-out 300ms ease-in;
}

// FRAMING
@keyframes rtl-in {
    0%   { transform: scale(1,1)    translateX(calc(100% + 100px)); }
    50%  { transform: scale(1,1)    translateX(0); }
    57%  { transform: scale(1,1)    translateX(-120px); }
    64%  { transform: scale(.8,1)   translateX(-50px); }
    100% { transform: scale(1,1)    translateX(0); }
}
@keyframes rtl-out {
    // 0%   { transform: scale(1,1)    translateX(0); }
    // 10%  { transform: scale(1.1,.9) translateX(0); }
    // 30%  { transform: scale(.9,1.1) translateX(-100px); }
    // 50%  { transform: scale(1,1)    translateX(0); }
    // 57%  { transform: scale(1,1)    translateX(-7px); }
    // 64%  { transform: scale(1,1)    translateX(-90%); }
    // 100% { transform: scale(1,1)    translateX(calc(-100% - 100px)); opacity: 0; }
    0%   { opacity: 1; }
    100%   { opacity: 0; }
}

@keyframes ltr-in {
    0%   { transform: scale(1,1)    translateX(calc(-100% - 100px)); }
    50%  { transform: scale(1,1)    translateX(0); }
    57%  { transform: scale(1,1)    translateX(120px); }
    64%  { transform: scale(.8,1)   translateX(30px); }
    100% { transform: scale(1,1)    translateX(0); }
}
@keyframes ltr-out {
    // 0%   { transform: scale(1,1)    translateX(0); }
    // 10%  { transform: scale(1.1,.9) translateX(0); }
    // 30%  { transform: scale(.9,1.1) translateX(-100px); }
    // 50%  { transform: scale(1,1)    translateX(0); }
    // 57%  { transform: scale(1,1)    translateX(7px); }
    // 64%  { transform: scale(1,1)    translateX(90%); }
    // 100% { transform: scale(1,1)    translateX(calc(100% + 100px)); opacity: 0; }
    0%   { opacity: 1; }
    100%   { opacity: 0; }
}