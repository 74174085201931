@mixin dropshadow {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

@mixin dropshadowSmall {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}

$textColor:     rgb(50,50,50);
$accentColor:               red;
$accentColor-20:            rgba(red, .2);
$errorColor:                crimson;

$gray-100:                                                  #f9fafc;
$gray-200:                                                  #f8fafd;
$gray-300:                                                  #e7eaf3;
$gray-400:                                                  #bdc5d1;
$gray-500:                                                  #97a4af;
$gray-600:                                                  #8c98a4;
$gray-700:                                                  #677788;
$gray-800:                                                  #71869d;
$gray-900:                                                  #1e2022;


$softTopPadding: 50px;
$softBorderRadius: 20px;

$pageMinHeight: 500px;
$pagePadding: 30px;
$pagePadding-m: 20px;
$itemPadding: 6px;
$itemPaddingLarge: 20px;

$uiItemRadius: 6px;
$uiItemRadiusBig: 12px;

// $formItemBg: rgba(8, 23, 62, 0.04);
$formItemBg: rgba(0,0,0, .05);
$formItemColor: rgb(100,100,100);
$formItemBgActive:rgba(0,0,0, .15);
$formItemColorActive: rgb(0,0,0);
$formItemBorderRadius: 6px;
$formItemMargin: 2px;

$inputSize: 40px;

$checkmarkBorder: 2px;
$checkmarkSize: 20px;
$checkmarkCheckedSize: 10px;
$checkBorder: 2px solid $gray-300;
$checkActiveColor: $textColor;
$checkBorderActive: 2px solid $textColor;

$iconColor: $accentColor;
$iconSizeSmall: 20px;

$btnColor: $textColor;
$btnBGColor: $accentColor-20;
$btnColorActive: white;
$btnBGColorActive: $accentColor;
$btnColorDisabled: $gray-100; 
$btnBGColorDisabled: $gray-400; 
$btnSize: 35px;
$btnSizeSmall: 35px;

$modalBackground: rgba(0, 0, 0, .6);
$modalBorderRadius: 40px;

$dropShadowColor: rgba(0, 0, 0, .3);



